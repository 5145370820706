<template>
  <card-container backButton closeButton>
    <div class="p-8 space-y-10 text-center">
      <div class="mt-14 text-8xl">🤩</div>
      <div class="text-3xl">Takk for at du vil dele!</div>
      <div class="space-y-6">
        <div>
          Husk at overnatting eller telting må skje mer enn 150 meter unna bebodd hus eller hytte.
        </div>
        <div>Enkelte steder er det lokale forbud mot overnatting, disse er normalt skiltet.</div>
      </div>
      <div>
        <button
          @click="$router.push({ name: 'new', params: { transition: 'slide-forward' } })"
          class="px-12 py-3 text-xl border-2 rounded-full"
        >
          Fortsett
        </button>
      </div>
    </div>
  </card-container>
</template>
<script>
import CardContainer from '../components/CardContainer.vue'
export default {
  components: { CardContainer },
}
</script>
